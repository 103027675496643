module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5425XW","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"dataLayer"}},"routeChangeEventName":"pageview","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"n4tx8jzt","include_in_development":false,"delay_timeout":0,"hide_default_launcher":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aurelius","short_name":"Aurelius","start_url":"/","background_color":"#ffffff","theme_color":"#ff8500","display":"minimal-ui","icon":"src/images/favicons/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"64ffbb18837c061ea542909cf0d08677"},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
